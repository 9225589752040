import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  autoPartsHero,
  autoPartsSubHero,
  autoPartsWhiteGlove,
  autoPartsLargeFeatureA,
  autoPartsTrusted,
  autoPartsMultiSection,
  autoPartsCTA,
  autoPartsVideo,
  // autoPartsTestimonials,
  // autoPartsArticles,
  // autoPartsFaqs,
  autoPartsCar,
} from "../../../data/auto-parts-data";
// import NoVisualsCustom from '../../../components/NoVisuals/NoVisualsCustom';
// import WhiteGlove from '../../../components/WhiteGlove/WhiteGlove';
// import LargeFeatures from '../../../components/LargeFeatures/LargeFeatures';
// import TrustedNumbers from '../../../components/TrustedNumbers/TrustedNumbers';
// import LargeCta from '../../../components/CTAs/LargeCta';
// import SuccessStories from '../../../components/SuccessStories/SuccessStories';
// import VideoSection from '../../../components/VideoSection/VideoSection';
// import BusinessTypes from '../../../components/BusinessTypes/BusinessTypes';
// import Articles from '../../components/Articles/Articles';
// import { tempData } from '../../data/article-data';
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import Faqs from '../../components/FAQs/Faqs';
import heroBg from "../../../images/hero-images/auto-parts-hero.png";

import {
  heroSuccess,
  NorthEastAutoSlides,
} from "../../../data/success-stories-data";
import { autoRepairArticles } from "../../../data/auto-repair-data";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);

const whiteGloveImport = "auto-repair-wg.png";

const AutoParts = () => {
  return (
    <Layout>
      <SEO
        title="Auto Parts Point of Sale (POS) Software | SpotOn"
        description="SpotOn's Auto Parts POS System is an all-in-one POS offering payment processing, appointment booking & marketing solutions. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/automotive/auto-parts/"
      />
      <BHero sectionData={autoPartsHero} heroBg="auto-parts-hero.png" />
      <NoVisualsCustom sectionData={autoPartsSubHero} complex />
      <WhiteGlove
        sectionData={autoPartsWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={autoPartsLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={autoPartsTrusted} />
      <LargeFeatures
        sectionData={autoPartsMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={autoPartsCTA} />
      <SuccessStories sectionData={heroSuccess} slides={NorthEastAutoSlides} />
      <VideoSection sectionData={autoPartsVideo} />
      {/* <TestmonialReviews sectionData={autoPartsTestimonials} /> */}
      <Articles
        sectionData={autoRepairArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:auto",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={autoPartsCar} />
      {/* <Faqs sectionData={autoPartsFaqs} /> */}
    </Layout>
  );
};

export default AutoParts;
