// eslint-disable-next-line import/prefer-default-export
export const autoPartsHero = {
  title: "Auto parts point-of-sale (POS) software",
  subtext:
    "Attract new customers, increase sales, and get paid faster with easy-to-use software built for the auto parts industry.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-parts/demo",
  },
};
export const autoPartsSubHero = {
  title: "Compete and win with SpotOn",
  subtext:
    "The world is changing a mile a minute. If you blink, you can get left behind in the competitive auto parts industry. We’re here to help with intuitive tools to engage your customers and get paid faster.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-parts/demo",
  },
  list: [
    "Attract new customers with a strong online presence",
    "Improve your customer experience",
    "Make it easier for your customers to pay you",
    "Improve customer retention to grow revenue",
  ],
};

export const autoPartsWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Get the platinum service you deserve",
  content:
    "Like you, we believe in delivering exactly what our customers are looking for. We assess your unique requirements and deliver a custom-fit solution for your business needs. And we don’t stop there. We’ll teach you how to get the most from your new tools, and then we’ll continue to earn your business every day with local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-parts/demo",
  },
};

export const autoPartsLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Powering performance with the parts you need",
      blockSubTitle:
        "Have you struggled to stand out amongst the competition, or to bring in new customers and keep them coming back? At SpotOn, we’ll help you do just that with custom-designed solutions—from payments, to marketing, to loyalty—that make you the go-to auto parts store. ",
      blockList: [
        "Transparent pricing",
        "Next-day funding",
        "No hidden fees",
        "No long-term commitment",
      ],
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-parts/demo",
      },
    },
  ],
};

export const autoPartsTrusted = [
  {
    title: "91%",
    subtext:
      "Of Millennials trust online reviews as much as friends and family",
  },
  {
    title: "88%",
    subtext: "Of people search online before visiting a business",
  },
  {
    title: "50%",
    subtext: "Of loyal customers are more likely to try new products",
  },
];

export const autoPartsMultiSection = {
  featureBlocks: [
    {
      blockTitle: "Attract customers with a new website",
      blockSubTitle:
        "Don’t spend your time building your own website or hiring an expensive web developer. Our specialists will build a professional website for you at a do-it-yourself price, so you show up higher in online searches and attract more customers.",
      blockList: [
        "Custom website design",
        "Online appointment booking",
        "Web hosting",
        "Custom website name",
        "Self-service dashboard",
      ],
      blockImg: "auto-parts-website.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-parts/demo",
      },
    },
    {
      blockTitle: "Grow customer loyalty. And revenue",
      blockSubTitle:
        "Increase sales by offering loyalty rewards that encourage your customers to visit more often. SpotOn Loyalty is easy to set up, easy to run, and your customers will love it. Most importantly, it will help you build your customer database so you can stay connected with your customers.",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      blockImg: "loyalty-mobile-laptop.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-parts/demo",
      },
    },
    {
      blockTitle: "Make payments & invoicing easy",
      blockSubTitle:
        "If you supply parts to local shops, our Virtual Terminal gives you a fast and professional way to invoice your clients, set up recurring billing plans, and let your clients pay by credit card. We can also help you save money on in-store payments.",
      blockList: [
        "Online payments & deposits",
        "In-store payments",
        "Custom invoices & reminders",
        "Recurring payments",
      ],
      blockImg: "payment-hardware.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-parts/demo",
      },
    },
    {
      blockTitle: "Market like a pro",
      blockSubTitle:
        "We make it easy to connect with your customers, whether it’s to send a limited-time deal,  advertise new products, or announce new hours. With SpotOn Marketing, you’ll be able to send branded emails and deals quickly and easily to every customer who has enrolled in your loyalty program or has their card on file in your Virtual Terminal.",
      blockImg: "marketing-outlined.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-parts/demo",
      },
    },
    {
      blockTitle: "Manage reviews. Boost your reputation",
      blockSubTitle:
        "Don’t leave your online reputation to chance. Our review management software makes it easy to monitor all your reviews from sites like Yelp, Google, and Facebook in one place. You also get automated alerts when new reviews are posted—so that you can not only monitor your reputation, but also improve it.",
      blockImg: "reviews-phone.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-parts/demo",
      },
    },
  ],
};

export const autoPartsCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "auto-repair-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-parts/demo",
  },
};

export const autoPartsVideo = {
  title: "Used by pros",
  videoBtn: "Watch video",
  posterImg: "oil-thumb.png",
  posterWrapperClass: "bg-blue",
  videoUrlId: "7I3implX9xc",
};

export const autoPartsTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const autoPartsArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const autoPartsFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/automotive/auto-parts/demo",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const autoPartsCar = {
  title: "Run a different type of auto business",
  punctuation: "?",
  subtext:
    "Your customers expect top-notch service from you, so why expect anything less with your merchant services? We’ll always put in the extra work to make sure you have the right tools to succeed with every new challenge.",
  slides: [
    {
      bg: "auto-repair.png",
      cardTitle: "Auto repair",
      cardContent: "Drive repeat visits and more",
      icon: "auto-repair.png",
      linkTo: "/automotive/auto-repair",
    },
    {
      bg: "car-sales.png",
      cardTitle: "Auto sales",
      cardContent: "Tools to grow your business\n",
      icon: "auto-sales.png",
      linkTo: "/automotive/auto-sales",
    },
    {
      bg: "tire-center.png",
      cardTitle: "Tire center",
      cardContent: "Tech to drive efficiency and revenue",
      icon: "tire-shop.png",
      linkTo: "/automotive/tire-center",
    },
    {
      bg: "oil-change.png",
      cardTitle: "Oil Change Shop",
      cardContent:
        "Find more customers, book more appointments, keep them coming back",
      icon: "oil-change.png",
      linkTo: "/automotive/oil-change-shop",
    },
  ],
};
