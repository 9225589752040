// eslint-disable-next-line import/prefer-default-export
export const autoRepairHero = {
  title: "Auto repair POS system",
  subtext:
    "Keep your service bays filled and your shop running smoothly with smarter tools for managing scheduling, getting paid, and increasing sales.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-repair/demo",
  },
};
export const autoRepairSubHero = {
  title: "Simple solutions to grow your auto repair business",
  subtext:
    "You face new challenges every day with customers expecting modern conveniences and faster service. SpotOn is here to help you adapt and thrive.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-repair/demo",
  },
  list: [
    "Attract new customers with a strong online presence",
    "Improve your customer experience",
    "Manage appointments and team schedules",
    "Improve customer retention to grow revenue",
    "Make it easier for your customers to pay you",
  ],
};

export const autoRepairsWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "We’re driven to serve you",
  content:
    "You work hard to give your customers the best service. At SpotOn, we do the same. Our team of experts will work hand-in-hand with you to assess your needs and create a solution that makes life easier for you, your employees, and your customers. We’ll teach you how to get the most from your new tools, and then we’ll continue to earn your business every day with local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-repair/demo",
  },
};

export const autoRepairLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Keep it simple",
      blockSubTitle:
        "You don’t have time to waste on confusing, mismatched software and payment solutions. Our cloud-based tools are thoughtfully designed to work together, with a single login, whether you’re in the shop or keeping tabs on your business from your mobile phone. And our pricing is fair, so you pay less while earning more.",
      blockList: [
        "Transparent pricing",
        "Next-day funding",
        "No hidden fees",
        "No long-term commitment",
      ],
      blockImg: "auto-repair-olo.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-repair/demo",
      },
    },
  ],
};

export const autoRepairTrusted = [
  {
    title: "30 - 40%",
    subtext: "Increase in sales with online appointment booking",
  },
  {
    title: "88%",
    subtext: "Of people search online before visiting a business",
  },
  {
    title: "50%",
    subtext: "Increase in purchase of new services from loyal customers",
  },
];

export const autoRepairMultiSection = {
  featureBlocks: [
    {
      blockTitle: "Increase sales with online booking",
      blockSubTitle:
        "SpotOn Appointments makes life easier for you and your customers while increasing sales by letting customers book appointments in advance from your website or Facebook page.",
      blockList: [
        "Online appointment booking",
        "Interactive calender for drop-offs & appointments",
        "Automated appointment reminders",
        "Employee shift management",
        "Mailing list builder",
      ],
      blockImg: "auto-repair-olo-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-repair/demo",
      },
    },
    {
      blockTitle: "Grow customer loyalty. And revenue",
      blockSubTitle:
        "Increase sales by offering loyalty rewards that encourage your customers to visit more often. SpotOn Loyalty is easy to set up, easy to run, and your customers will love it. Most importantly, it will help you build your customer database so you can stay connected with your customers.",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      blockImg: "loyalty-mobile-laptop.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-repair/demo",
      },
    },
    // {
    //   blockTitle: 'Make payments & invoicing easy',
    //   blockSubTitle:
    //     'If you supply parts to local shops, our Virtual Terminal gives you a fast and professional way to invoice your clients, set up recurring billing plans, and let your clients pay by credit card. We can also help you save money on in-store payments.',
    //   blockList: [
    //     'Online payments & deposits',
    //     'In-store payments',
    //     'Custom invoices & reminders',
    //     'Recurring payments',
    //   ],
    //   blockImg: 'payment-hardware.png',
    //   forceReverse: false,
    //   ctaPrime: {
    //     ctaTitle: 'Learn more',
    //     ctaTarget: '/automotive/auto-repair/demo',
    //   },
    // },
    {
      blockTitle: "Attract customers with a new website",
      blockSubTitle:
        "There’s no reason to sink time into building your own website. Our specialists will build a professional website for you at a do-it-yourself price, so you show up higher in online searches and attract more customers.",
      blockList: [
        "Custom website design",
        "Online appointment booking",
        "Lifetime support",
        "Custom website name",
        "Self-service dashboard",
      ],
      blockImg: "auto-parts-website.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-repair/demo",
      },
    },
    {
      blockTitle: "Cut the wires. Connect with customers. Get paid",
      blockSubTitle:
        "SpotOn Terminal makes it easy to take payments wherever your customers are—in the lobby, in the garage, or curbside. And with full Marketing and Loyalty integration, your customers can redeem deals and loyalty rewards during checkout with ease",
      blockImg: "auto-repair-z.png",
      blockList: [
        "Apple Pay, Google Pay, and NFC enabled",
        "Wi-Fi and 4G connectivity",
        "Built-in receipt printer and scanner",
        "Cash discount and surcharge ready",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-repair/demo",
      },
    },
    {
      blockTitle: "Market like a pro",
      blockSubTitle:
        "We make it easy to connect with your customers, whether it’s to send a limited-time deal,  advertise new products, or announce new hours. With SpotOn Marketing, you’ll be able to send branded emails and deals quickly and easily to every customer who has enrolled in your loyalty program or has their card on file in your Virtual Terminal.",
      blockImg: "marketing-outlined.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-repair/demo",
      },
    },
  ],
};

export const autoRepairCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "auto-repair-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-repair/demo",
  },
};

export const autoRepairVideo = {
  title: "Used by pros",
  videoBtn: "Watch video",
  posterImg: "oil-thumb.png",
  posterWrapperClass: "bg-blue",
  videoUrlId: "4pIhYB_LvJM",
};

export const autoRepairTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const autoRepairArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const autoRepairFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/automotive/auto-repair/demo",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const autoRepairCar = {
  title: "Run a different type of auto business",
  punctuation: "?",
  subtext:
    "Your customers expect top-notch service from you, so why expect anything less with your merchant services? We’ll always put in the extra work to make sure you have the right tools to succeed with every new challenge.",
  slides: [
    {
      bg: "car-sales.png",
      cardTitle: "Auto sales",
      cardContent: "Tools to grow your business\n",
      icon: "auto-sales.png",
      linkTo: "/automotive/auto-sales",
    },
    {
      bg: "auto-parts.png",
      cardTitle: "Auto parts",
      cardContent: "Tools to grow your business\n",
      icon: "auto-parts.png",
      linkTo: "/automotive/auto-parts",
    },
    {
      bg: "tire-center.png",
      cardTitle: "Tire center",
      cardContent: "Tech to drive efficiency and revenue",
      icon: "tire-shop.png",
      linkTo: "/automotive/tire-center",
    },
    {
      bg: "oil-change.png",
      cardTitle: "Oil Change Shop",
      cardContent:
        "Find more customers, book more appointments, keep them coming back",
      icon: "oil-change.png",
      linkTo: "/automotive/oil-change-shop",
    },
  ],
};
